import {
  OptionalString,
  StringEnum,
  ModuleCategory,
  createComponentAdmin,
  type SchemaTypeHelper,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';
import {
  numberValidationOptionGroupSchema,
  textValidationOptionGroupSchema,
  InputTypes,
} from './helpers/react-hook-form/typebox-helpers';

export const reactName = 'TextInput';
export const name = 'Input';
export const description = reactName;
const category: ModuleCategory = 'form';

export const schema = Type.Object(
  {
    name: Type.String({title: 'Input Id'}),
    label: OptionalString({title: 'Input Label'}),
    placeholder: OptionalString({title: 'Input Placeholder'}),
    inputType: StringEnum(InputTypes, {title: 'Input Type'}),
    sizing: Type.Optional(
      Type.Object({
        size: Type.Optional(
          StringEnum(['sm', 'md', 'lg'] as const, {
            title: 'Size',
            default: 'md',
          })
        ),
      })
    ),
  },
  {
    dependencies: {
      inputType: {
        oneOf: [
          {
            properties: {
              inputType: {
                enum: ['text'],
              },
              validationOptions: textValidationOptionGroupSchema,
            },
          },
          {
            properties: {
              inputType: {
                enum: ['number'],
              },
              validationOptions: numberValidationOptionGroupSchema,
            },
          },
        ],
      },
    },
  }
);

export const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    sections: [
      [
        'Properties',
        [
          {
            'ui:template': 'accordion',
            sections: [
              ['General', ['name', 'label', 'placeholder', 'inputType']],
              ['Validation Options', ['validationOptions']],
            ],
          },
        ],
      ],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [['Custom Styles', ['styleAttr']]],
          },
        ],
      ],
      ['Animations', []],
    ],
  },
};

export const defaultFieldData: Static<typeof schema> = {
  inputType: 'text',
  name: 'default-name',
  label: 'My Input Label',
  sizing: {
    size: 'md',
  },
};

export const ComponentDefinition = createComponentAdmin({
  id: '440d33c5-3a8e-47ab-a03c-12f4c5edecb7',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  instructions: Type.Union([]),
  slotConfiguration: {},
  schema,
  uiSchema,
  category,
})
  .withAnimationStates()
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition>;
